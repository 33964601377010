<template>
    <div class="animated fadeIn">
      <div id="snackbar"></div>
        <b-card>
          <b-row>
            <b-col sm="4">
              <b-form-group v-if="userPrmission">
                <label for="cinemas">Cinema <span class="text-danger">*</span></label>
                <b-form-select required v-model="CinemaID" id="cinemas"
                  :plain="true"
                  value-field="CinemaID"
                  text-field="CinemaName"
                  :options=cinemas
                  @change.native="selectCinema($event.target.value)"
                  >
                  <template slot="first">
                        <option :value="'0'" disabled>-- Select Cinema --</option>
                    </template>
                </b-form-select>
              </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group>
                  <label for="userReportmovieList">Movie<span class="text-danger">*</span></label>
                  <b-form-select required v-model="userReport.selectedMovie" id="Movie"
                    :plain="true"
                     value-field="MovieID"
                    text-field="Title"
                    :options=userReport.movieList
                    >
                    <template slot="first">
                        <option :value="'0'" disabled>-- Select Movie --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4">
                <b-form-group>
                  <label for="userReportmovieList">Genre<span class="text-danger">*</span></label>
                  <b-form-select required v-model="userReport.selectedGenre" id="Genre"
                    :plain="true"
                    value-field="GenreID"
                    text-field="GenreName"
                    :options=userReport.genreList
                    >
                    <template slot="first">
                        <option :value="'0'" disabled>-- Select Genre --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group>
                  <label for="userReportNoofVisits">No Of Visits<span class="text-danger">*</span></label>
                  <b-form-input required  type="number" step="any" v-model="userReport.noOfVisits" id="userReportNoofVisits" placeholder="No Of Visits"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col style="margin: auto" sm="4">
                   <b-form-group>
                        <label for=""></label>
                        <b-button v-on:click="selectCinema()" type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Submit</b-button>
                    </b-form-group>
                </b-col> 
              
            </b-row>
            <b-card-header>
              <b-row>
                  <b-col>
                  User Report 
                  </b-col>                
                  <b-col class="col d-flex justify-content-end">
                      <b-button v-if="data.length > 0" :disabled="data.length > 0 ? false: true" v-on:click="onDownloadPDF('PDF')" type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i>Download as PDF</b-button>&nbsp;&nbsp;
                      <b-button v-if="data.length > 0" :disabled="data.length > 0 ? false: true" v-on:click="onDownloadExcel('XLSX')" type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i>Download as XLSX</b-button>
                  </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
                <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
                  
                  <span slot="DateOfTransaction" slot-scope="props" v-html="props.row.DateOfTransaction"></span>
                  <!-- <span slot="DateOfTransaction" slot-scope="props" v-html="props.row.DateOfTransaction.split(',').join('<br/>')"></span> -->
                </v-client-table>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { ClientTable, Event } from "vue-tables-2";
import CinemaService from "@/services/CinemaService";
import MovieService from "@/services/MovieService";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { pdfFillColor } from "@/helper/constant";
import { dashboardName,WANASHAdashboardName } from "@/helper/constant";
import XLSX from "xlsx";

export default {
  name: "Users-Report",
  components: {
    cSwitch
  },
  data() {
    return {
      // Saif
      userReport: {
        selectedMovie: "0",
        movieList: [],
        noOfVisits: "",
        selectedGenre: "0",
        genreList: []
      },
      userPrmission: true,
      CinemaID: "0",
      cinemas: [],
      columns: [
        "Email",
        "Mobile",
        "DateOfTransaction",
        "NumberOfTickets",
        "MovieName",
        "Genre",
        "NumberOfVisits"
      ],
      data: [],
      options: {
        headings: {
          Email: "User Email",
          Mobile: "User Mobile",
          DateOfTransaction: "Date of Transaction",
          NumberOfTickets: "No. of Tickets",
          MovieName: "MovieName",
          Genre: "Genre",
          NumberOfVisits: "No. of Visits"
        },
        sortable: [
          "Email",
          "Mobile",
          "DateOfTransaction",
          "NumberOfTickets",
          "MovieName",
          "Genre",
          "NumberOfVisits"
        ],
        filterable: [
          "Email",
          "Mobile",
          "DateOfTransaction",
          "NumberOfTickets",
          "MovieName",
          "Genre",
          "NumberOfVisits"
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  beforeMount() {
    if (
      localStorage.getItem("UserRole") &&
      localStorage.getItem("UserRole") == "Cinema"
    ) {
      this.userPrmission = false;
    } else {
      this.getCinemasDDL(0);
    }
    this.selectCinema();
    this.getMovies();
    this.getGenreList();
  },
  mounted() {},
  methods: {
    onDownloadExcel: function(type) {
      if (this.data.length > 0) {
        var dataOne = XLSX.utils.json_to_sheet(this.data);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataOne, "USERS"); // sheetAName is name of Worksheet
        XLSX.writeFile(
          wb,
          `${WANASHAdashboardName.toUpperCase()}_USERS_REPORT_${new Date().toDateString()}.xlsx`
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    onDownloadPDF: function(type) {
      if (this.data.length > 0) {
        let mainArray = [];

        this.data.map(x => {
          let tempArray = [];
          this.columns.map(y => {
            if (x.hasOwnProperty(y)) {
              tempArray.push(x[y]);
            }
          });
          mainArray.push(tempArray);
        });
        var imgData = BASE64IMAGE;

        var doc = new jsPDF("l", "px", [1000, 600]);

        doc.addImage(imgData, "JPEG", 280, 0, 150, 100);
        doc.text(280, 100, `${WANASHAdashboardName.toUpperCase()} USERS REPORT`);
        doc.setFontSize(17);

        doc.autoTable({
          head: [this.columns],
          theme: "grid", // 'striped'|'grid'|'plain'
          body: mainArray,
          margin: { top: 120 },
          headStyles: {
            fillColor: pdfFillColor
          }
        });
        doc.save("USERS_REPORT_" + new Date().toDateString() + ".pdf");
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    onFilterSubmit: function() {},
    getGenreList: function() {
      // let res = await MovieService.getGenreList();

      MovieService.getGenreList()
        .then(response => {
          const { data } = response;
          if (data.Status && data.GenreList.length > 0) {
            this.userReport.genreList = data.GenreList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getMovies: function() {
      // let res = await MovieService.getMoviesDDL();
      this.userReport.movieList = [];
      MovieService.getMoviesDDL()
        .then(response => {
          const { data } = response;
          if (data.Status && data.MovieList.length > 0) {
            this.userReport.movieList = data.MovieList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Error-----", error);

          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCinemasDDL: function(OrgID) {
      this.cinemas = [];
      // let res = await CinemaService.getCinemasDDL(0);
      let payload = {
        OrgID
      };
      CinemaService.getCinemasDDL(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CinemaList.length > 0) {
            this.cinemas = data.CinemaList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    selectCinema: function() {
      let payload = {
        CinemaID: this.CinemaID == "0" ? "" : this.CinemaID,
        MovieID:
          this.userReport.selectedMovie == "0"
            ? ""
            : this.userReport.selectedMovie,
        NoOfVisits: this.userReport.noOfVisits,
        Genre:
          this.userReport.selectedGenre !== "0"
            ? this.userReport.selectedGenre
            : ""
      };
      this.data = [];
      // let res = await CinemaService.getUserReportsData(payload);
      CinemaService.getUserReportsData(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.UserReportList.length > 0) {
            let sortArray = data.UserReportList;
            sortArray.map(x => {
              let string = "";
              x.MovieName.map((y, _index) => {
                string =
                  _index == x.MovieName.length - 1
                    ? string + y
                    : string + y + ", ";
              });
              x.MovieName = string;
            });
            if (
              this.userReport.noOfVisits !== "" &&
              this.userReport.noOfVisits > 0
            ) {
              // this.data = res.data.Data.filter(
              this.data = sortArray.filter(
                x =>
                  String(x.NumberOfVisits) == String(this.userReport.noOfVisits)
              );
            } else {
              this.data = sortArray;
            }
            // this.CinemaID = "0";
            // this.userReport.selectedMovie = "0";
            // this.userReport.noOfVisits = "";
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          // console.log("Error-----", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>

<style>
</style>
